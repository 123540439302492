import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

function useConsultationRoutes() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  async function loadConsultationFormByRouteParamsAndQueries() {
    store.commit("setConsultationForm", {
      key: "cd_especialista",
      value: (route.params.cd_especialista || route.query.st)?.toString(),
    });
    store.commit("setConsultationForm", {
      key: "cd_especialidade",
      value: (route.params.cd_especialidade || route.query.sy)?.toString(),
    });
    store.commit("setConsultationForm", {
      key: "cd_estabelecimento",
      value: (route.params.cd_estabelecimento || route.query.et)?.toString(),
    });
    store.commit("setConsultationForm", {
      key: "cd_pessoa_fisica",
      value: (route.params.cd_pessoa_fisica || route.query.pt)?.toString(),
    });
    store.commit("setConsultationForm", {
      key: "schedule",
      value: (route.params.schedule || route.query.se)?.toString(),
    });

    const { date, time } = getSelectedDateAndTime();
    store.commit("setConsultationForm", { key: "date", value: date });
    store.commit("setConsultationForm", { key: "time", value: time });

    const form = store.state.consultation.form;
    const promises: any[] = [];

    if (form.cd_especialista && !store.state.specialist.current)
      promises.push(store.dispatch("getSpecialist", { _id: form.cd_especialista }));
    if (form.cd_estabelecimento && !store.state.establishment.current)
      promises.push(store.dispatch("getEstablishment", { _id: form.cd_estabelecimento }));
    if (promises.length) await Promise.allSettled(promises);

    const specialties = store.state.establishment.current?.especialidades || [];
    if (specialties.length && form.cd_especialidade)
      store.commit(
        "setCurrentSpecialty",
        (specialties.find((item) => String(item.cd_especialidade) === String(form.cd_especialidade)) as any) || null
      );
  }

  function getSelectedDateAndTime(dateAndTime?: string | null) {
    const value = (dateAndTime || route.query.date || route.query.dt)?.toString().split("_") || [];
    return { date: value[0], time: value[1] };
  }

  const defaultConsultationParamsAndQueries = () => ({
    query: {
      st: (route.params.cd_especialista || route.query.st)?.toString(),
      sy: (route.params.cd_especialidade || route.query.sy)?.toString(),
      et: (route.params.cd_estabelecimento || route.query.et)?.toString(),
      pt: (route.params.cd_paciente || route.query.pt)?.toString(),
      nv: (route.params.nr_valor || route.query.nv)?.toString(),
      se: (route.params.schedule || route.query.se)?.toString(),
      dt: route.query.dt?.toString(),
    },
    params: {
      schedule: (route.params.schedule || route.query.se)?.toString(),
      cd_especialista: (route.params.cd_especialista || route.query.st)?.toString(),
      cd_especialidade: (route.params.cd_especialidade || route.query.sy)?.toString(),
      cd_estabelecimento: (route.params.cd_estabelecimento || route.query.et)?.toString(),
      cd_paciente: (route.params.cd_paciente || route.query.pt)?.toString(),
      nr_valor: (route.params.nr_valor || route.query.nv)?.toString(),
      noScroll: "true",
    },
  });

  const consultationSpecialtyRoute = () => ({
    name: "consultation-specialty",
    ...defaultConsultationParamsAndQueries(),
  });

  const consultationSpecialistRoute = () => ({
    name: "consultation-specialist",
    ...defaultConsultationParamsAndQueries(),
  });

  const consultationScheduleRoute = (flow = "specialist") => ({
    name: `consultation-${flow}-schedule`,
    ...defaultConsultationParamsAndQueries(),
  });

  const consultationEstablishmentRoute = () => ({
    name: "consultation-specialist-establishment",
    ...defaultConsultationParamsAndQueries(),
  });

  const consultationConfirmRoute = () => ({
    name: "consultation-confirm",
    ...defaultConsultationParamsAndQueries(),
  });

  function goToConsultationSpecialty() {
    router.push(consultationSpecialtyRoute());
  }

  function goToConsultationSpecialist() {
    router.push(consultationSpecialistRoute());
  }

  function goToConsultationSchedule(
    cd_especialista?: string | number | null,
    cd_especialidade?: string | number | null,
    nr_valor?: string | number | null,
    cd_estabelecimento?: string | number | null,
    flow?: "specialty" | "specialist"
  ) {
    const routeTo = { ...consultationScheduleRoute(flow) };
    if (cd_especialista) routeTo.params.cd_especialista = cd_especialista.toString();
    if (cd_especialidade) routeTo.params.cd_especialidade = cd_especialidade.toString();
    if (cd_estabelecimento) routeTo.params.cd_estabelecimento = cd_estabelecimento.toString();
    if (nr_valor) routeTo.params.nr_valor = nr_valor.toString();

    router.push(routeTo);

    store.commit("setConsultationForm", {
      key: "cd_especialista",
      value: routeTo.params.cd_especialista,
    });
    store.commit("setConsultationForm", {
      key: "cd_especialidade",
      value: routeTo.params.cd_especialidade,
    });

    if (routeTo.params.cd_estabelecimento)
      store.commit("setConsultationForm", {
        key: "cd_estabelecimento",
        value: routeTo.params.cd_estabelecimento,
      });

    if (routeTo.params.nr_valor)
      store.commit("setConsultationForm", {
        key: "nr_valor",
        value: routeTo.params.nr_valor,
      });
  }

  function goToConsultationEstablishment(schedule?: string | number | null, dateAndTime?: string | null) {
    const routeTo = { ...consultationEstablishmentRoute() };
    if (schedule) routeTo.params.schedule = schedule.toString();
    if (dateAndTime) routeTo.query.dt = dateAndTime;

    router.push(routeTo);

    const { date, time } = getSelectedDateAndTime(dateAndTime);
    store.commit("setConsultationForm", { key: "date", value: date });
    store.commit("setConsultationForm", { key: "time", value: time });
  }

  function goToConsultationConfirm(cd_estabelecimento?: string | number | null) {
    const routeTo = { ...consultationConfirmRoute() };
    if (cd_estabelecimento) routeTo.params.cd_estabelecimento = cd_estabelecimento.toString();

    router.push(routeTo);

    store.commit("setConsultationForm", {
      key: "cd_estabelecimento",
      value: routeTo.params.cd_estabelecimento,
    });
  }

  return {
    consultationSpecialtyRoute,
    consultationSpecialistRoute,
    consultationScheduleRoute,
    consultationEstablishmentRoute,
    consultationConfirmRoute,
    goToConsultationSpecialty,
    goToConsultationSpecialist,
    goToConsultationSchedule,
    goToConsultationEstablishment,
    goToConsultationConfirm,
    getSelectedDateAndTime,
    loadConsultationFormByRouteParamsAndQueries,
  };
}

export { useConsultationRoutes };
